
import * as cloudinaryRuntime$jZRuVlVIhbQZ0SfHQOBG41xzLFs5iChtGXYS5zWahWU from '/opt/render/project/src/node_modules/.pnpm/@nuxt+image@1.10.0_@netlify+blobs@8.1.2_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as cmsProviderRuntime$kP0fe4QLg3oL4iIWR83wlIYHB3eeihlWsLbC6P85wcg from '/opt/render/project/src/apps/refresh/providers/cms.js'
import * as ipxRuntime$sQzcoTIhH3BcaiczlT139ztpp7pSfqdzDJ2qv6W8Z10 from '/opt/render/project/src/node_modules/.pnpm/@nuxt+image@1.10.0_@netlify+blobs@8.1.2_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1920,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$jZRuVlVIhbQZ0SfHQOBG41xzLFs5iChtGXYS5zWahWU, defaults: {"baseURL":"https://res.cloudinary.com/klandestino-ab/image/upload"} },
  ['cmsProvider']: { provider: cmsProviderRuntime$kP0fe4QLg3oL4iIWR83wlIYHB3eeihlWsLbC6P85wcg, defaults: {"baseURL":"https://res.cloudinary.com/klandestino-ab/image/fetch"} },
  ['ipx']: { provider: ipxRuntime$sQzcoTIhH3BcaiczlT139ztpp7pSfqdzDJ2qv6W8Z10, defaults: {} }
}
        