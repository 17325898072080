<template>
  <component
    :is="forms[formComponentId]"
    v-bind="formComponentId === 'petition' ? { petitionId } : {}"
  />
</template>

<script setup>
import MembershipMonthlyOrDonation from './MembershipMonthlyOrDonation.vue'
import MembershipMonthly from './MembershipMonthly.vue'
import DonationStandard from './DonationStandard.vue'
import DonationSwish from './DonationSwish.vue'
import DonationCounter from './DonationCounter.vue'
import PetitionBlock from './PetitionForm.vue'

const forms = {
  component_id_1: MembershipMonthlyOrDonation, // TODO: Remove, legacy
  membership_monthly: MembershipMonthly,
  membership_monthly_or_donation: MembershipMonthlyOrDonation,
  donation_counter: DonationCounter,
  donation: DonationStandard,
  swish_number: DonationSwish,
  petition: PetitionBlock,
}

defineProps({
  formComponentId: String,
  petitionId: String,
})
</script>
