<template>
  <div class="mb-6">
    <span v-if="subTitle" :class="subTitleVariants({ theme })">
      {{ subTitle }}
    </span>
    <div
      class="radio-wrapper"
      :class="
        twMerge(
          `radio-${theme}`,
          wrapperVariants({ theme }),
          modelValue === value && wrapperActiveVariants({ theme }),
        )
      "
      @click="onClick"
    >
      <input
        type="radio"
        :checked="modelValue === value"
        :value="value"
        :class="inputVariants({ theme })"
      />
      <label :class="labelVariants({ theme })">
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { THEMES } from '../../types/themes'
const props = withDefaults(
  defineProps<{
    label: string
    subTitle?: string
    modelValue: string | number
    value: string | number
    theme?: THEMES
  }>(),
  {
    theme: THEMES.DEFAULT,
  },
)

const emit = defineEmits(['update:modelValue'])

const onClick = () => emit('update:modelValue', props.value)

const subTitleVariants = cva('mb-2 text-sm uppercase leading-none', {
  variants: {
    theme: {
      [THEMES.DEFAULT]: 'font-serif text-black',
      [THEMES.RDK]: 'font-avenir text-purple-dark font-normal',
      [THEMES.DJURENS_RATT]: 'font-serif text-black',
    },
  },
  defaultVariants: {
    theme: THEMES.DEFAULT,
  },
})

const wrapperVariants = cva(
  'group flex cursor-pointer items-center rounded-md bg-white px-5 py-4 hover:text-white',
  {
    variants: {
      theme: {
        [THEMES.DEFAULT]:
          'shadow-[0_4px_30px_0px_rgba(0,180,210,0.2)] hover:bg-blue-dark text-blue',
        [THEMES.RDK]: 'shadow-[0_4px_30px_0_rgba(122,121,174,0.25)] hover:bg-[#6236B6]',
        [THEMES.DJURENS_RATT]:
          'shadow-[0_4px_30px_0px_rgba(0,180,210,0.2)] hover:bg-blue-dark text-blue',
      },
    },
    defaultVariants: {
      theme: THEMES.DEFAULT,
    },
  },
)

const wrapperActiveVariants = cva('[&_label]:font-bold [&_label]:text-white', {
  variants: {
    theme: {
      [THEMES.DEFAULT]: 'bg-blue text-white ',
      [THEMES.RDK]: ' bg-purple text-white   ',
      [THEMES.DJURENS_RATT]: 'bg-blue text-white ',
    },
  },
  defaultVariants: {
    theme: THEMES.DEFAULT,
  },
})

const inputVariants = cva('cursor-pointer ring-0', {
  variants: {
    theme: {
      [THEMES.DEFAULT]: 'text-blue group-hover:text-blue-dark []',
      [THEMES.RDK]: 't text-purple group-hover:text-[#6236B6]',
      [THEMES.DJURENS_RATT]: 'text-blue group-hover:text-blue-dark',
    },
  },
  defaultVariants: {
    theme: THEMES.DEFAULT,
  },
})

const labelVariants = cva('ml-7 cursor-pointer text-base leading-[26px] group-hover:text-white', {
  variants: {
    theme: {
      [THEMES.DEFAULT]: ' text-black font-sans',
      [THEMES.RDK]: 'font-avenir text-purple-dark',
      [THEMES.DJURENS_RATT]: ' text-black font-sans',
    },
  },
  defaultVariants: {
    theme: THEMES.DEFAULT,
  },
})
</script>

<style scoped>
.radio-djurens_ratt.radio-wrapper,
.radio-default.radio-wrapper {
  --borderColor: #00b4d2;
  --hoverColor: #0088a4;
}

.radio-rdk.radio-wrapper {
  --borderColor: #7a54c3;
  --hoverColor: #6236b6;
}

input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  margin: 0;
  font: inherit;
  width: 24px;
  height: 24px;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;
  position: relative;
}

input[type='radio']:before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 120ms transform ease-in-out;
  box-shadow: none;
  background-color: #ffffff;
}

input[type='radio']:checked:before {
  transform: scale(1);
  background-color: var(--borderColor);
}

.radio-wrapper:hover input[type='radio']:checked:before {
  background-color: var(--hoverColor);
}

input[type='radio']:checked {
  border: 7px solid #ffffff;
}

input[type='radio']:focus {
  outline: 0;
  outline: none !important;
  box-shadow: none !important;
}
input[type='radio']:checked:hover {
  outline: 0;
  background-color: #ffffff;
  border: 7px solid #ffffff;
  outline: none !important;
  box-shadow: none !important;
}

[type='radio']:checked:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: #ffffff;
  border: 7px solid #ffffff;
}
</style>
