<template>
  <div class="flex w-full justify-between">
    <button
      v-if="props.prevStep?.title"
      :class="activeButtonVariant({ theme })"
      @click="$emit('prev')"
    >
      <ArrowRight class="mr-2 rotate-180" />
      <span>
        {{ props.prevStep?.title }}
      </span>
      <span v-if="props.prevStep?.showInNavigation">
        : {{ props.prevStep?.showInNavigation }}
      </span>
    </button>
    <div v-else></div>
    <div v-if="props.nextStep?.title" :class="navTextVariant({ theme })">
      <span>
        {{ props.nextStep?.title }}
      </span>
      <ArrowRight :class="arrowVariant({ theme })" />
    </div>
    <div v-else></div>
  </div>
</template>

<script lang="ts" setup>
import { cva } from 'class-variance-authority'
import { THEMES } from '../../types/themes'
import ArrowRight from '../atoms/Icons/ArrowRight.vue'

defineEmits(['next', 'prev'])

const props = withDefaults(
  defineProps<{
    nextStep: any
    prevStep: any
    theme?: THEMES
  }>(),
  {
    theme: THEMES.DEFAULT,
  },
)

const activeButtonVariant = cva(
  'flex h-[52px] items-center px-5 text-center font-[BaileySans] text-sm uppercase leading-none  lg:px-0 lg:text-lg',
  {
    variants: {
      theme: {
        [THEMES.DEFAULT]: 'text-[#00B4D2] hover:text-[#0088A4]',
        [THEMES.RDK]: 'text-purple hover:text-purple-dark',
      },
    },
    defaultVariants: {
      theme: THEMES.DEFAULT,
    },
  },
)

const arrowVariant = cva('ml-2', {
  variants: {
    theme: {
      [THEMES.DEFAULT]: 'text-[#D9D9D9]',
      [THEMES.RDK]: 'text-grey-700',
    },
  },
  defaultVariants: {
    theme: THEMES.DEFAULT,
  },
})

const navTextVariant = cva(
  'flex h-[52px] items-center px-5 text-center font-[BaileySans] text-sm uppercase leading-none lg:px-0 lg:text-lg',
  {
    variants: {
      theme: {
        [THEMES.DEFAULT]: 'text-[#D9D9D9]',
        [THEMES.RDK]: 'text-grey-700',
      },
    },
    defaultVariants: {
      theme: THEMES.DEFAULT,
    },
  },
)
</script>
