<template>
  <div class="mt-4">
    <p class="my-4 hidden font-sans text-base lg:block">
      {{ $t('choose-a-sum-to-contribute') }}
    </p>
    <div class="flex space-x-2 lg:space-x-4">
      <Button
        v-for="(sum, index) in sums"
        :key="index"
        :title="`${sum.value} ${sum.currency}`"
        class="flex-1 !py-2 lowercase lg:!py-2 lg:leading-none"
        class-name-text="text-sm lg:text-base "
        :type="sumSelected === sum.value ? undefined : 'outline'"
        @click="handleClickSum(sum.value)"
      />
      <Button
        :title="$t('other')"
        class="flex-1 !py-2 lowercase lg:!py-2 lg:leading-none"
        class-name-text="text-sm lg:text-base "
        :type="otherSum ? undefined : 'outline'"
        @click="handleClickOther"
      />
    </div>

    <Input
      v-show="otherSum"
      v-model="sumSelected"
      type="number"
      :min="0"
      :label="$t('your-amount-to-donate')"
      style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] text-blue text-sm lg:text-base font-bold !pr-10 w-40"
      :currency="currency"
      class="mt-6 items-start"
      :right="otherRightString"
    />
  </div>
  <Link
    :title="$t('next-step')"
    color="pink"
    class="mt-8 w-full lg:w-auto"
    :link="urlSelected + sumSelected"
    :external="true"
  />
</template>

<script setup lang="ts">
/** Single donation form */
import { ref } from 'vue'
import { Input, Button, Link } from 'refresh-ui'
import { useI18n } from '#imports'
const { t } = useI18n()
const config = useRuntimeConfig()

const is1882 = ref(config.public.siteName.includes('1882'))

const currency = is1882.value ? '€' : 'kr'
// TODO: Implement minSum and validation

const minSum = is1882.value ? 5 : 50
const sums = is1882.value
  ? [
      { value: 50, currency },
      { value: 25, currency },
      { value: 10, currency },
    ]
  : [
      { value: 200, currency },
      { value: 100, currency },
      { value: 75, currency },
    ]

const otherRightString = `<span class='absolute right-4 bottom-3 lg:bottom-4 text-sm font-bold text-blue lg:text-base'>${currency}</span>`

const urlSelected = ref(t('component-form.donation.url'))
const sumSelected = ref(sums[1].value)
const otherSum = ref(false)

const handleClickSum = (sum: number) => {
  sumSelected.value = sum
  otherSum.value = false
}

const handleClickOther = () => {
  otherSum.value = true
  sumSelected.value = 0
}
</script>
