<!-- eslint-disable vue/no-v-html -->
<template>
  <Container class="flex flex-col items-center">
    <div class="max-w-[650px] lg:max-w-full">
      <div class="md:flex md:flex-col md:items-center md:text-center"></div>
      <div class="xmd:pt-8 w-full">
        <CollapseImageGrid
          v-for="(item, index) in data?.cards"
          :key="item?.title || index"
          :open="(data?.firstExpanded && index === 0) || isOpen"
          @click="toggleOpen"
        >
          <template #header> {{ item.title }}</template>
          <template #content>
            <div
              :class="[
                width < 365
                  ? 'my-5 -ml-1 grid grid-cols-2 gap-3  '
                  : 'my-5 grid grid-cols-2 gap-3 md:grid-cols-2 md:gap-6 lg:grid-cols-4 lg:gap-12 ',
              ]"
            >
              <!-- TODO: When all cards are updated in Drupal the check for the precense of links to determine background/border color should be removed -->
              <div
                v-for="i in sortedArray(item?.sub)"
                :class="[
                  item.cardType === 'org' && i?.cardColor === 'grey'
                    ? 'relative h-[307px] w-[165px] overflow-hidden rounded-xl border border-grey shadow-md md:h-[374px] md:w-[230px]'
                    : i.links || i?.cardColor === 'blue'
                      ? 'relative h-[307px] w-[165px] overflow-hidden rounded-xl border border-blue-light shadow-md md:h-[374px] md:w-[230px]'
                      : item.cardType === 'org' && (!i?.links || i?.cardColor === 'red')
                        ? 'relative h-[307px] w-[165px] overflow-hidden rounded-xl border border-red-500 shadow-lg md:h-[374px] md:w-[230px]'
                        : 'relative w-[165px] overflow-hidden rounded-xl border border-blue-light shadow-md md:h-[220px] md:w-[230px]',
                ]"
              >
                <div :key="i.cardTitle" class="flex flex-col justify-items-center">
                  <div
                    :class="[
                      i.links
                        ? 'flex h-[179px] w-full flex-col justify-center gap-1 text-center md:h-[220px] md:w-[230px]'
                        : 'flex h-[179px] w-full flex-col justify-center gap-1 rounded-xl border border-blue-lighter text-center shadow-md md:h-[220px] md:w-[230px]',
                    ]"
                  >
                    <GridImage v-if="i?.image" :url="i.image.url" :class="'self-center'" />
                    <h3>{{ i?.cardTitle }}</h3>
                  </div>
                  <div
                    v-if="item.cardType === 'org' && (!i?.links || i?.cardColor === 'red')"
                    class="absolute bottom-0 flex h-32 w-full flex-col justify-center gap-y-3 bg-red-100 p-2 text-center md:h-[153px] md:w-[228px] md:gap-y-4"
                  >
                    <p class="self-center font-serif text-lg uppercase text-[#D95151]">
                      <!-- @vue-skip -->
                      {{ $t('no-policy') }}
                    </p>
                  </div>

                  <div
                    v-if="item.cardType === 'org' && i?.cardColor === 'grey'"
                    class="absolute bottom-0 flex h-32 w-full flex-col justify-center gap-y-3 bg-grey-light p-2 text-center md:h-[153px] md:w-[228px] md:gap-y-4"
                  >
                    <p class="self-center font-serif text-lg uppercase text-black">
                      <!-- @vue-skip -->
                      {{ $t('policy-in-works') }}
                    </p>
                  </div>
                  <div
                    v-if="i?.links || i?.cardColor === 'blue'"
                    class="absolute bottom-0 flex h-32 w-full flex-col justify-center gap-y-3 bg-blue-lighter p-2 text-center md:h-[153px] md:w-[228px] md:gap-y-4"
                  >
                    <LinkSecondary
                      v-if="i?.links"
                      :title="i?.links[0]?.title"
                      :link="i?.links[0]?.url"
                      :class="[
                        'self-center rounded-full bg-blue px-4 py-1.5 font-serif text-lg font-normal hover:bg-blue-dark lg:w-[130px] lg:!text-lg',
                      ]"
                      :arrow-class="'ml-2.5'"
                      :external="i?.links[0]?.type === 'ext'"
                    />
                    <Link
                      v-if="i?.links && i?.links?.length > 1"
                      :title="i?.links[1]?.title"
                      :link="i?.links[1]?.url"
                      :class="[
                        'w-[138px] self-center whitespace-nowrap !rounded-full border-none bg-green !px-3.5 !py-1.5 font-serif text-lg font-normal hover:bg-green-dark lg:w-[140px] lg:!text-lg',
                      ]"
                      :external="i?.links[0]?.type === 'ext'"
                    />
                    <p
                      v-if="i?.links?.length === 1 && item.cardType === 'org'"
                      class="self-center font-serif text-lg uppercase text-[#D95151]"
                    >
                      <!-- @vue-skip -->
                      {{ $t('no-report') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CollapseImageGrid>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useWindowSize } from '@vueuse/core'
// TODO: Fix this Nuxt specific import

import Link from '../atoms/Link.vue'
import LinkSecondary from '../atoms/LinkSecondary.vue'
import Container from '../atoms/Container.vue'
import GridImage from '../atoms/GridImage.vue'
import CollapseImageGrid from '../atoms/CollapseImageGrid.vue'

import type { ICardData, ILinkData, IImageData, CardColor } from '../../types'

interface ISub {
  cardTitle?: string
  links?: ILinkData[]
  image?: IImageData
  cardColor?: CardColor
}

interface ICardSmallImageGridBlock extends ICardData {
  sub?: ISub[]
  cardType?: 'org' | 'general'
}

export interface ISmallImageGridBlock {
  id?: string
  cards?: ICardSmallImageGridBlock[]
  link?: ILinkData
  firstExpanded?: boolean
}

interface Props {
  data: ISmallImageGridBlock
}

defineProps<Props>()

const isOpen = ref(false)
const toggleOpen = () => {
  isOpen.value = !isOpen.value
}

const { width } = useWindowSize()

const sortedArray = (array?: ISub[]) => {
  if (array === undefined) return []

  return array.sort((a, b) => {
    if (a.cardTitle && b.cardTitle) {
      return a.cardTitle > b.cardTitle ? 1 : -1
    } else {
      // handle cases where either cardTitle is undefined
      return 0
    }
  })
}
</script>
