import routerOptions0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_a030c5c461dfde5a54f0d321891762de/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/render/project/src/apps/refresh/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}