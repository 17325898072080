<template>
  <template v-for="element in components" :key="element.id">
    <component
      :is="blocks[element.type]"
      :id="slugify(element.title || element.id || '')"
      :data="element"
      :data-type="element.type"
      class="mx-auto w-full max-w-[1920px]"
    />

    <BigHeroCtaBlock
      v-if="element.type === BIG_CTA_BLOCK"
      :id="slugify(element.title || element.id)"
      :data="element"
      class="mx-auto w-full max-w-[1920px]"
    >
      <template #default="{ formComponentId, petitionId }">
        <CtaFormComponent :form-component-id="formComponentId" :petition-id="petitionId" />
      </template>
    </BigHeroCtaBlock>
  </template>
</template>

<script setup>
import { InfoFormBlock, PetitionBlock } from '@/components/elements/DynamicBlocks'
import CtaFormComponent from '@/components/molecules/CtaFormComponent/index.vue'
import slugify from '@sindresorhus/slugify'

import {
  IntroBlock,
  ImageBlock,
  BigHeroCtaBlock,
  BigArgumentBlock,
  ContentHtml,
  CardRowBlock,
  CompactNewsBlock,
  FaqBlock,
  ListBlock,
  TabsBlock,
  FactsBlock,
  SmallImageGridBlock,
  VideoBlock,
  ContentTextBlock,
  CTABannerBlock,
  ImageTextBlock,
  OnThisPageBlock,
  ShortZebraBlock,
  TinySliderBlock,
  TestimonialBlock,
  NewsCardSliderBlock,
  TallCardSliderBlock,
  TimelineSliderBlock,
  SearchStringsBlock,
  ListAutoBlock,
  ExpandableSourcesBlock,
  TitleTextBlock,
  PodcastsBlock,
} from 'refresh-ui'

import {
  FAQ_BLOCK,
  LIST_BLOCK,
  TABS_BLOCK,
  INTRO_BLOCK,
  SMALL_IMAGE_GRID_BLOCK,
  VIDEO_BLOCK,
  FACTS_BLOCK,
  IMAGE_BLOCK,
  CONTENT_HTML_BLOCK,
  CONTENT_TEXT_BLOCK,
  PODCAST_BLOCK,
  BIG_CTA_BLOCK,
  CARD_ROW_BLOCK,
  INFO_FORM_BLOCK,
  CTA_BANNER_BLOCK,
  IMAGE_TEXT_BLOCK,
  SHORT_ZEBRA_BLOCK,
  TINY_SLIDER_BLOCK,
  TESTIMONIAL_BLOCK,
  BIG_ARGUMENT_BLOCK,
  COMPACT_NEWS_BLOCK,
  ON_THIS_PAGE_BLOCK,
  TIMELINE_SLIDER_BLOCK,
  TALL_CARD_SLIDER_BLOCK,
  NEWS_CARD_SLIDER_BLOCK,
  SEARCH_STRINGS_BLOCK,
  LIST_AUTO_BLOCK,
  PETITION_BLOCK,
  EXPANDABLE_BLOCK,
  TITLE_TEXT_BLOCK,
} from 'refresh-common'

defineProps({
  components: Array,
})

const blocks = {
  [FAQ_BLOCK]: FaqBlock,
  [LIST_BLOCK]: ListBlock,
  [TABS_BLOCK]: TabsBlock,
  [INTRO_BLOCK]: IntroBlock,
  [SMALL_IMAGE_GRID_BLOCK]: SmallImageGridBlock,
  [VIDEO_BLOCK]: VideoBlock,
  [FACTS_BLOCK]: FactsBlock,
  [IMAGE_BLOCK]: ImageBlock,
  [CONTENT_HTML_BLOCK]: ContentHtml,
  [CONTENT_TEXT_BLOCK]: ContentTextBlock,
  [CARD_ROW_BLOCK]: CardRowBlock,
  [PODCAST_BLOCK]: PodcastsBlock,
  [INFO_FORM_BLOCK]: InfoFormBlock,
  [CTA_BANNER_BLOCK]: CTABannerBlock,
  [IMAGE_TEXT_BLOCK]: ImageTextBlock,
  [SHORT_ZEBRA_BLOCK]: ShortZebraBlock,
  [TINY_SLIDER_BLOCK]: TinySliderBlock,
  [TESTIMONIAL_BLOCK]: TestimonialBlock,
  [ON_THIS_PAGE_BLOCK]: OnThisPageBlock,
  [BIG_ARGUMENT_BLOCK]: BigArgumentBlock,
  [COMPACT_NEWS_BLOCK]: CompactNewsBlock,
  [TIMELINE_SLIDER_BLOCK]: TimelineSliderBlock,
  [TALL_CARD_SLIDER_BLOCK]: TallCardSliderBlock,
  [NEWS_CARD_SLIDER_BLOCK]: NewsCardSliderBlock,
  [SEARCH_STRINGS_BLOCK]: SearchStringsBlock,
  [LIST_AUTO_BLOCK]: ListAutoBlock,
  [PETITION_BLOCK]: PetitionBlock,
  [EXPANDABLE_BLOCK]: ExpandableSourcesBlock,
  [TITLE_TEXT_BLOCK]: TitleTextBlock,
}
</script>
