import isoCountries from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import sv from 'i18n-iso-countries/langs/sv.json'
import uk from 'i18n-iso-countries/langs/uk.json'
import es from 'i18n-iso-countries/langs/es.json'

export type LangType = 'en' | 'sv' | 'uk' | 'es'

const language = {
  en,
  sv,
  uk,
  es,
}

export const useCountries = (lang: LangType = 'en') => {
  // set language
  isoCountries.registerLocale(language[lang])

  const countryCodes = Object.fromEntries(
    Object.entries(isoCountries.getNumericCodes()).map(([key]) => [
      key,
      isoCountries.getName(key, lang),
    ]),
  )

  const countryNames = Object.fromEntries(
    Object.entries(isoCountries.getNumericCodes()).map(([, value]) => [
      isoCountries.getName(value, lang),
      isoCountries.getName(value, lang),
    ]),
  )

  const countries = isoCountries.getNames(lang, { select: 'official' })

  return { countryCodes, countryNames, countries }
}
