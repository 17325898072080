<template>
  <div
    :class="
      twMerge(
        barVariant({ theme }),
        currentStep + 1 === totalSteps
          ? 'rounded-r-0 sm:rounded-[10px]'
          : 'rounded-r-0 sm:rounded-[10px]',
      )
    "
  >
    <div
      :class="
        twMerge(
          barActiveVariant({ theme }),
          currentStep + 1 === totalSteps
            ? 'rounded-r-0 sm:rounded-r-[8px] lg:rounded-[10px]'
            : 'rounded-r-[8px] lg:rounded-[10px]',
        )
      "
      :style="{ width: progressWidth }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { cva } from 'class-variance-authority'
import { THEMES } from '../../types/themes'
import { twMerge } from 'tailwind-merge'

const props = withDefaults(
  defineProps<{
    currentStep: number
    totalSteps: number
    theme?: THEMES
  }>(),
  {
    currentStep: 0,
    totalSteps: 0,
    theme: THEMES.DEFAULT,
  },
)

const barVariant = cva('h-[8px] w-full lg:h-[10px]', {
  variants: {
    theme: {
      [THEMES.DEFAULT]: 'bg-[#EBF2F3]',
      [THEMES.RDK]: 'bg-purple-light',
      [THEMES.DJURENS_RATT]: 'bg-[#EBF2F3]',
    },
  },
  defaultVariants: {
    theme: THEMES.DEFAULT,
  },
})

const barActiveVariant = cva(
  'h-full transition-[width] duration-300 ease-out sm:rounded-l-[10px]',
  {
    variants: {
      theme: {
        [THEMES.DEFAULT]: 'bg-[#00B4D2]',
        [THEMES.RDK]: 'bg-purple',
        [THEMES.DJURENS_RATT]: 'bg-[#00B4D2]',
      },
    },
    defaultVariants: {
      theme: THEMES.DEFAULT,
    },
  },
)

const progressWidth = computed(() => {
  return `${((props.currentStep + 1) / props.totalSteps) * 100}%`
})
</script>
