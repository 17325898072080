<template>
  <iframe
    v-if="url"
    loading="lazy"
    :src="`https://www.youtube-nocookie.com/embed/${id}?rel=0${params}`"
    allowfullscreen
    :title="id"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  id?: string
  url?: string
  params?: string
}

const props = withDefaults(defineProps<Props>(), {
  id: '',
  url: '',
  params: '',
})

const youtubeRegexp =
  /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi

const getIdFromURL = (url?: string) => {
  let id = url ? url.trim().replace(youtubeRegexp, '$1') : ''

  if (id.includes(';')) {
    const pieces = id.split(';')

    if (pieces[1].includes('%')) {
      const uriComponent = decodeURIComponent(pieces[1])
      id = `http://youtube.com${uriComponent}`.replace(youtubeRegexp, '$1')
    } else {
      id = pieces[0]
    }
  } else if (id.includes('#')) {
    id = id.split('#')[0]
  }

  return id
}

const id = computed(() => getIdFromURL(props.url))
</script>
