/**
 * Extracts field titles from a nested webform `elements` array.
 *
 * This function returns a flat object mapping field keys (like `preferences_allergies`)
 * to their corresponding `#title` values. It can optionally filter by field key prefixes
 * (e.g., only fields starting with `preferences_` or `options_`).
 *
 * Example output:
 * {
 *   "preferences_allergies": "Allergier",
 *   "preferences_share_room_with": "Vem vill du dela rum med"
 *
 * You can use it in `3` ways:
 *
 * 1. Extract all field titles (no filtering)
 *    extractFieldTitles(elements)
 *    -> returns all fields with titles from the webform
 *
 * 2. Extract only fields with a specific prefix
 *    extractFieldTitles(elements, ['preferences_'])
 *    -> returns only preference fields, e.g. { preferences_allergies: 'Allergier' }
 *
 * 3. Extract multiple types of fields by prefixes
 *    extractFieldTitles(elements, ['preferences_', 'options_'])
 *    -> returns both preferences and options fields with their titles
 *
 * @param elements - The `data.elements` array from the form definition
 * @param prefixes - Optional array of field key prefixes to include (e.g., ['preferences_'])
 * @returns A record mapping field keys to their readable labels
 */

export const extractFieldTitles = (
  elements: any[],
  prefixes: string[] = [],
): Record<string, string> => {
  const titles: Record<string, string> = {}
  // Helper: check if a field key matches one of the allowed prefixes
  const matchesPrefix = (key: string) =>
    prefixes.length === 0 || prefixes.some(prefix => key.startsWith(prefix))

  // Recursively walk through nested fields to collect titles
  const recurse = (obj: Record<string, any>) => {
    for (const [key, value] of Object.entries(obj)) {
      // If the key matches prefix, and has a title, store it
      if (matchesPrefix(key) && typeof value === 'object' && value['#title']) {
        titles[key] = value['#title']
      }
      // Continue recursively through any nested objects
      else if (typeof value === 'object') {
        recurse(value)
      }
    }
  }

  // Start recursion for each top-level fieldset/group
  elements.forEach(([, group]) => {
    recurse(group)
  })

  return titles
}
