<template>
  <div>
    <div v-if="isProtest" class="text-md mb-6 font-serif font-bold text-black lg:text-xl">
      <CountUpDisplay
        :key="petitionsKey"
        :end-val="petitions"
        :options="{
          enableScrollSpy: true,
          separator: ' ',
        }"
      />
      {{ $t('petitionCounter.alreadySigned') }}
    </div>
    <div v-else class="text-md mb-6 font-serif font-bold text-black lg:text-xl">
      <CountUpDisplay
        :key="petitionsKey"
        :end-val="petitions"
        :options="{
          enableScrollSpy: true,
          separator: ' ',
        }"
      />
      {{ $t('petitionCounter.alreadySigned') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { standardSeries, findNext } from '~/libs/petitions'
import { ref as dbRef, onValue } from 'firebase/database'
import { CountUpDisplay } from 'refresh-ui'

const props = defineProps({
  isClosed: {
    type: Boolean,
    required: false,
    default: false,
  },
  formId: {
    type: String,
    required: true,
  },
  isProtest: {
    type: Boolean,
    required: false,
    default: false,
  },
})

// Bind to realtime counter value
const db = useDatabase()
const countRef = dbRef(db, 'counters/' + props.formId)
const petitionsKey = ref(0)
const petitions = ref(0)

onValue(countRef, async snapshot => {
  petitions.value = snapshot.val() || 0
  petitionsKey.value++
})

// Calculate counter per
const percent = computed(() => 'width:' + (petitions.value / goal.value) * 100 + '%')

// Calculate goal
const goal = computed(() => {
  return props.isClosed ? petitions.value : findNext(petitions.value, standardSeries)
})
</script>
