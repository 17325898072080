<template>
  <form>
    <ModalLoader v-if="formStatus === 'submitting' || formStatus === 'redirecting'" />
    <div
      :class="{
        'rounded-xl bg-blue-lighter px-6 py-4 lg:rounded-2xl lg:p-9': !isMinimize,
        'p-0': isMinimize,
      }"
    >
      <h4 class="mb-10 font-serif text-xl text-black lg:text-2xl" v-if="!isMinimize">
        {{ isProtest ? $t('petitionForm.protestTitle') : $t('petitionForm.petitionTitle') }}
      </h4>
      <MultiStepForm :steps="blocks">
        <template #[blocks.step1.name]="{ nextStep }">
          <div class="flex flex-col lg:flex-row lg:space-x-4">
            <Input
              v-model="data.person.firstname"
              :label="$t('firstName')"
              :v$="v$.person.firstname"
              style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] w-full "
              style-error="relative bg-transparent"
              class="mb-4"
              :class="{
                'lg:mb-7': !isMinimize,
                '': isMinimize,
              }"
              required
            />
            <Input
              v-model="data.person.lastname"
              :label="$t('lastName')"
              :v$="v$.person.lastname"
              style-error="relative bg-transparent"
              style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] w-full"
              required
              class="mb-4"
              :class="{
                'lg:mb-7': !isMinimize,
                '': isMinimize,
              }"
            />
          </div>
          <div>
            <Input
              v-model.trim="data.person.email"
              :label="$t('email')"
              type="email"
              :v$="v$.person.email"
              style-error="relative bg-transparent"
              style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] w-full"
              class="mb-4"
              :class="{
                'lg:mb-7': !isMinimize,
                '': isMinimize,
              }"
              required
            />

            <MultiSelect
              v-if="siteLang !== 'sv'"
              :title="t('country')"
              style-input="w-full lg:w-[400px]"
              :options="countries"
              v-model="data.person.countryCode"
              :v$="v$.person.countryCode"
              openDirection="top"
              :maxHeight="250"
              required
            />
            <div class="flex items-center justify-stretch justify-items-center">
              <Button
                :title="$t('petitionForm.nextLabel')"
                class="mb-2 w-full hover:bg-blue-lighter hover:text-blue lg:mt-6 lg:max-w-[200px]"
                @click.prevent="nextStep"
                :disabled="v$.person.$invalid ? true : undefined"
              />

              <PetitionCounterMini
                v-if="isMinimize"
                :is-closed="false"
                :is-protest="isProtest"
                :form-id="dbOverride || petitionId"
                class="pl-3 pt-3 lg:pl-10 lg:pt-10"
              />
            </div>

            <div
              v-if="!isMinimize"
              class="pt-5 font-sans text-xs leading-normal text-black lg:text-sm lg:leading-relaxed [&_p]:text-xs lg:[&_p]:text-sm"
            >
              {{ $t('petitionForm.info') }}
            </div>
          </div>
        </template>
        <template #[blocks.step2.name]="{ prevStep }">
          <Textarea
            :label="
              $t('petitionForm.messageLabel', {
                protest: isProtest ? $t('petitionForm.messageLabelProtestAddition') : '',
              })
            "
            v-model="data.message"
            style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] w-full"
            style-error="!relative !bg-transparent"
            class="mb-3"
          />
          <!--<CheckboxGroup
            :label="$t('petitionForm.consentLabel')"
            v-model="data.consent"
            :description="$t('petitionForm.consentDescription')"
            class="mt-6"
          />-->
          <div
            v-if="v$?.$invalid && v$?.$dirty && v$?.$errors?.length > 0"
            class="mt-2 flex w-full flex-col items-start rounded bg-blue-lighter px-1 pr-3 pt-0 font-sans text-xs text-pink lg:text-sm"
            role="alert"
          >
            <span v-for="err in v$.$errors" :key="err.$uid">{{ err.$message }}</span>
          </div>
          <Button
            :title="
              isProtest ? $t('petitionForm.submitProtest') : $t('petitionForm.submitPetition')
            "
            class="mb-4 mt-6 w-full px-6 lg:mb-6 lg:mt-10 lg:w-auto lg:px-12"
            color="pink"
            :disabled="v$.$invalid || formStatus !== 'idle' ? true : undefined"
            @click.prevent="submitPetition"
          />
          <div class="mt-2 text-sm lg:text-base [&_a]:font-bold [&_a]:underline">
            <p>
              {{
                $t(isProtest ? 'petitionForm.integrity.protest' : 'petitionForm.integrity.petition')
              }}
              <a :href="$t('integrityUrl')" target="_blank">{{
                $t('petitionForm.integrity.more')
              }}</a
              >.
            </p>
          </div>
          <div class="mt-4">
            <button
              @click.prevent="prevStep"
              class="text-base font-bold text-blue hover:text-white"
            >
              &lt; <span class="underline">{{ $t('goBack') }}</span>
            </button>
          </div>
        </template>
      </MultiStepForm>
    </div>
    <div v-if="isDebug" class="mt-5 rounded-md bg-blue-lighter p-10 text-white">
      <Title :level="2" customStyle="pb-5 lg:text-2xl text-lg">Debug helpers</Title>
      <Link :to="resultUrl" class="mb-5" title="Go to the act result page" />
      <p class="max-w-sm text-black">{{ resultUrl }}</p>
      <Link
        :to="legacyUrl"
        class="mb-5"
        type="outline"
        color="blue"
        title="Go to the legacy interact page"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { navigateTo } from '#app'
import { Input, Button, Textarea, Title, Link, ModalLoader, MultiSelect } from 'refresh-ui'
import PetitionCounterMini from '@/components/molecules/PetitionCounterMini.vue'
import { buildResultUrl } from 'refresh-common'
import MultiStepForm from '@/components/molecules/MultiStepForm.vue'
import { useI18n } from '#imports'
import { withQuery, joinURL, parseURL } from 'ufo'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators'
import { useRuntimeConfig } from '#app'

const route = useRoute()

const isDebug = route.query.debug === 'true'

type lang = 'sv' | 'en' | 'uk' | 'es'

const { t } = useI18n()
const { siteLang, siteUrl } = useRuntimeConfig().public as { siteLang: lang; siteUrl: string }

const { countries } = useCountries(siteLang)

export interface Props {
  petitionId: string
  isProtest?: boolean
  endpointId: string
  dbOverride?: string
  campaignCode: string
  isMinimize?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isProtest: false,
  isMinimize: false,
  dbOverride: '',
})
/*
const props = defineProps({
  petitionId: String,
  isProtest: Boolean,
  endpointId: String,
  dbOverride: String,
  campaignCode: String,
})*/

const data = reactive({
  person: {
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    countryCode: '',
    postcode: '',
  },
  message: '',
  campaignCode: props.campaignCode,
  actionId: props.petitionId,
  dbOverride: props.dbOverride,
  siteLang,
})

if (siteLang === 'uk') {
  data.person.countryCode = 'UA'
} else if (siteLang === 'sv') {
  data.person.countryCode = 'SE'
}

const blocks = computed(() => ({
  step1: {
    name: 'step1',
  },
  step2: {
    name: 'step2',
  },
}))

const rules = {
  person: {
    firstname: {
      required: helpers.withMessage(t('firstName.requiredMessage'), required),
    },
    lastname: {
      required: helpers.withMessage(t('lastName.requiredMessage'), required),
    },
    email: {
      required: helpers.withMessage(t('email.requiredMessage'), required),
      email: helpers.withMessage(t('email.errorMessage'), email),
    },
    countryCode: {
      required: helpers.withMessage(t('country.requiredMessage'), required),
    },
  },
}

interface IValidationState {
  person: {
    firstname: string
    lastname: string
    email: string
    countryCode: string
  }
}

const v$ = useVuelidate<IValidationState>(rules, data)

const urlMap = {
  sv: 'https://act.djurensratt.se',
  en: 'https://act.project1882.org',
  uk: 'https://act-ua.project1882.org',
  es: 'https://act.project1882.org',
} as const

const resultUrl = computed(() => {
  // Used for petitions with a result page on act
  return buildResultUrl(urlMap[siteLang], {
    ...route.query, // grab any params and parse for accepted keys, used to preserve existing url params
    ...{
      fname: data.person.firstname,
      lname: data.person.lastname,
      email: data.person.email,
      countryCode: data.person.countryCode,
      slug: props.petitionId,
      sysId: props.endpointId,
      type: props.isProtest ? 'protest' : 'petition',
      lang: siteLang,
    },
  })
})

const legacyUrl = computed(() => {
  // Used for legacy petitions that should continue on interact
  return withQuery(joinURL('https://interact.djurensratt.se/agera/', props.petitionId || ''), {
    fname: data.person.firstname,
    lname: data.person.lastname,
    email: data.person.email,
    message: data.message,
  })
})

// TODO: Fix logic for legacy petitions, currently has quick override for the ec-promise petition so they all just run on the new variant
const isLegacy = computed(() => {
  const overridePetitionIds = [
    'emanuels-testinsamling',
    'regeringen-förbjud-turbokycklingar',
    'ec-promise-sv',
  ] // Hard coded values during a transition phase
  return siteLang === 'sv' && !overridePetitionIds.find(id => id === data.actionId)
})

type FormStatus = 'idle' | 'submitting' | 'redirecting' | 'success' | 'error'

const formStatus = ref<FormStatus>('idle')
const message = {
  referenceId: 'template-petition-1882-en',
  receivers: [
    {
      email: 'em.dahlstrom@djurensratt.se',
      vars: [
        {
          name: 'FNAME',
          content: 'Em',
        },
        {
          name: 'LNAME',
          content: 'Dahlström',
        },
      ],
    },
  ],
  sender: { email: 'noreply@project1882.org' },
  payload: {
    subject: 'Test Email Template',
    text: 'additional text',
    vars: [
      {
        name: 'FGLOBAL',
        content: `<p>Oh, how wonderful that you have participated in signing the petition Government – make decisions for animals 2024! It makes me so happy, a heartfelt thank you for that!</p>

<p>There is currently an opportunity for the government to create a more animal-friendly Sweden. Several important animal welfare issues are just a few decisions away from real change for millions of animals!</p>

<p>One of the important issues concerns the pigs. The carbon dioxide stunning they undergo before slaughter is so dreadful. The gas makes it difficult to breathe, and they struggle for their lives to get out. They are terrified in their final moments of life. 💔 We must put an end to the painful carbon dioxide stunning and suffering for the pigs.</p>

<p>That's why I would like to ask you for a little more help. If you have the opportunity, a donation from you would mean incredibly much to make change for the pigs happen. With your donation, we can put even more pressure on politicians and authorities to change the laws affecting pigs, in Sweden and at the EU level.</p>

<p>Together, we can ensure that this immense suffering comes to an end.</p>`,
      },

      {
        name: 'FGREETING',
        content: 'Привіт',
      },
      {
        name: 'FCTAURL',
        content: 'https://act.project1882.org/donation',
      },
      {
        name: 'FCTATEXT',
        content: 'Будь ласка, пожертвуйте',
      },
      {
        name: 'FTHANKS',
        content: 'Дякую за те, що ви підписалися заради тварин!',
      },
      {
        name: 'FCAMPAIGNIMAGE',
        content:
          'https://res.cloudinary.com/klandestino-ab/image/upload/f_auto,q_auto,w_1200,h_785,c_fill/djurensratt-se/agera/kampanjer/Kampanj_stoppa_blackfisk',
      },
      {
        name: 'FCAMPAIGNALT',
        content: 'An image of a happy dog',
      },
      {
        name: 'FORGLOGO',
        content: joinURL(siteUrl, '/external-use/', `logo-${siteLang}.png`),
      },
      {
        name: 'FORGSITEURL',
        content: siteUrl,
      },
      {
        name: 'FORGSITETEXT',
        content: parseURL(siteUrl).host,
      },
      {
        name: 'FORGEMAIL',
        content: 'ua@project1882.org',
      },
      {
        name: 'FORGINTEGRITYURL',
        content: 'https://www.project1882.org/privacy',
      },
      {
        name: 'FORGINTEGRITYTEXT',
        content: 'Цілісність',
      },
    ],
  },
}

// TODO: Add email sending logic
// console.log('message', JSON.stringify(message, null, 2))

/* const response = await $fetch('/api/send-mail', {
  method: 'POST',
  body: message,
})
console.log('res', response) */

const submitPetition = async () => {
  if (formStatus.value !== 'idle') return
  if (!props.petitionId) {
    console.log('Abort > No petitionId')
    return
  }
  if (v$.value.$invalid) {
    console.log('Abort > Invalid user input')
    return
  }
  try {
    if (isLegacy.value) {
      formStatus.value = 'redirecting'
      console.log('Interact redirect')
      await navigateTo(legacyUrl.value, { external: true })
      return
    }
    formStatus.value = 'submitting'
    console.log('In submit')
    const regRes = await $fetch('/api/petition', { method: 'POST', body: data })
    console.log('reg', regRes)

    if (regRes.case === 'already-added') {
      useNuxtApp().$toast.warning(t('petitionForm.errors.alreadySigned'), {
        position: 'top',
        duration: 10000,
      })
      formStatus.value = 'idle'
      return
    }

    if (regRes.case === 'error' || !regRes.success) {
      useNuxtApp().$toast.error(t('petitionForm.errors.server'), {
        position: 'top',
      })
      formStatus.value = 'idle'
      return
    }

    formStatus.value = 'redirecting'
    await navigateTo(resultUrl.value, { external: true })
  } catch (e) {
    console.error('Error submitting petition', e)
    formStatus.value = 'error'
    useNuxtApp().$toast.error(t('petitionForm.errors.server'), {
      position: 'top',
    })
  }
}
</script>
