<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="relative flex flex-1 flex-col">
    <label
      :class="
        twMerge(
          'mb-1.5 inline-block font-sans text-base text-black lg:mb-3 lg:text-lg lg:leading-snug',
          styleLabel,
        )
      "
    >
      {{ label }}
      <span v-if="required">*</span>
    </label>
    <div :class="twMerge('relative', styleInputWrapper)">
      <input
        v-model="numericInput"
        type="text"
        :class="
          twMerge(
            'max-h-[60px] flex-1 rounded-md border-transparent p-3 leading-none outline-0 focus:border-transparent focus:ring-0 disabled:border-[#ADBACB] disabled:bg-[#D8D8D8] lg:p-4 lg:text-xl lg:leading-none',
            styleInput,
            v$?.$invalid && v$?.$dirty && 'border-pink focus:border-pink',
          )
        "
        :placeholder="placeholder"
        :required="required"
        :min="min"
        :max="max"
        :name="name"
        :hidden="hidden"
        :disabled="disabled"
        :read-only="readOnly"
        @input="e => update(e, name)"
        @blur="v$ && v$.$validate()"
      />
      <div
        v-if="invalidMessage"
        :class="
          twMerge(
            'absolute mt-0.5 flex w-full items-center rounded px-1 py-0 pr-3 font-sans text-sm text-pink',
            styleError,
          )
        "
      >
        {{ invalidMessage }}
      </div>
      <div
        v-if="v$?.$invalid && v$?.$dirty && v$?.$errors?.length > 0"
        :class="
          twMerge(
            'absolute mt-2 flex w-full flex-col items-start rounded bg-blue-lighter px-1 py-0 pr-3 font-sans text-xs text-pink lg:text-sm',
            styleError,
          )
        "
        role="alert"
      >
        <span v-for="err in v$.$errors" :key="err.$uid">{{ err.$message }}</span>
      </div>

      <div v-if="right" v-html="right"></div>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { twMerge } from 'tailwind-merge'

const props = defineProps({
  label: String,
  styleInput: String,
  styleInputWrapper: String,
  styleLabel: String,
  styleError: String,
  pattern: { type: String, default: '' },
  patternError: { type: String, default: '' },
  name: { type: String, default: '' },
  type: {
    type: String,
    default: 'text',
  },
  required: { type: Boolean, default: false },
  placeholder: String,
  modelValue: [String, Number],
  modelModifiers: {
    type: Object,
    default: () => ({}),
  },
  right: String,
  min: Number,
  max: Number,
  disabled: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false },
  readOnly: { type: Boolean, default: false },
  v$: Object,
})

const numericInput = ref(props.modelValue)

const emit = defineEmits(['update:modelValue', 'setData'])

watch(
  () => props.modelValue,
  () => (numericInput.value = props.modelValue),
)

// TODO: Simplify this debounced validation
const invalidMessage = ref('')

const checkPattern = () => {
  if (!props.pattern) invalidMessage.value = ''
  const regex = new RegExp(props.pattern)
  return regex.test(String(numericInput.value))
    ? (invalidMessage.value = '')
    : (invalidMessage.value = props.patternError)
}

watchDebounced(
  numericInput,
  () => {
    checkPattern()
  },
  { debounce: 1000, maxWait: 5000 },
)

const update = (e: any, key: any) => {
  if (props.modelModifiers.trim === true && typeof numericInput.value === 'string') {
    numericInput.value = numericInput.value.trim()
  }
  // Note: Is this really necessary?
  if (props.type === 'number' && typeof String(numericInput?.value)?.replace === 'function') {
    numericInput.value = Number(String(numericInput.value).replace(/[^0-9]/g, ''))
  }
  emit('setData', { key, value: numericInput.value })
  emit('update:modelValue', numericInput.value)
}

onMounted(() => {
  nextTick(() => {
    update(null, props.name)
  })
})
</script>
