<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-full lg:max-w-[430px] 2xl:max-w-[630px]">
    <div class="px-4 lg:px-0">
      <ClientOnly fallback-tag="span" fallback="Loading ...">
        <Swiper
          class="tabs"
          :class="{ 'has-icon': hasIcon }"
          :slides-per-view="'auto'"
          :space-between="95"
          :mousewheel="true"
          :breakpoints="breakpoints"
          :modules="[Mousewheel, Controller]"
          :controller="{ control: controlledSwiperTab }"
          @swiper="setSwiperTab"
        >
          <div class="w-full border-b border-blue-light" v-if="hasIcon" />
          <SwiperSlide
            v-for="(item, index) in tabs"
            :key="index"
            class="flex w-full justify-center"
          >
            <div
              class="flex justify-center fill-black py-4 text-black hover:fill-blue hover:text-blue"
            >
              <span
                class="relative flex cursor-pointer items-center font-sans text-sm before:absolute before:-bottom-[16px] before:left-0 before:h-0.5 before:w-[1000%] before:bg-blue-line before:content-[''] after:absolute after:-bottom-[16px] after:left-0 after:z-10 after:h-0.5 after:w-full after:content-[''] lg:text-lg"
                :class="[
                  index === activeTab
                    ? 'fill-blue tracking-normal text-blue after:bg-blue'
                    : 'tracking-normal',
                  item.icon && 'min-w-10 justify-center',
                ]"
                @click="setActiveTab(index)"
              >
                <div v-if="item.icon" class="size-5" :class="[item.iconClass]">
                  <component
                    :is="item.icon"
                    v-if="item.icon"
                    class="size-5"
                    :class="[item.iconClass]"
                  />
                </div>
                <div class="relative flex" :class="[item.icon && 'ml-2']">
                  <span
                    :class="[
                      item.icon ? 'hidden lg:block' : 'block',
                      index === activeTab ? 'opacity-1' : 'opacity-0',
                    ]"
                    class="font-bold"
                  >
                    {{ item.tabLabel }}
                  </span>

                  <span
                    :class="[
                      item.icon ? 'hidden lg:block' : 'block',
                      index === activeTab ? 'opacity-0' : 'opacity-1',
                    ]"
                    class="absolute left-0"
                  >
                    {{ item.tabLabel }}
                  </span>
                </div>
              </span>
            </div>
          </SwiperSlide>
          <template v-slot:container-start>
            <div
              class="absolute bottom-4 left-0 z-50 bg-gradient-to-l from-transparent via-white to-white lg:hidden"
              v-if="!controlledSwiperTab?.isBeginning"
            >
              <button class="py-4 pr-10 text-blue" @click="controlledSwiperTab?.slidePrev()">
                <ChevronRight class="rotate-180" />
              </button>
            </div>
          </template>
          <template v-slot:container-end>
            <div
              class="absolute bottom-4 right-0 z-50 bg-gradient-to-r from-transparent via-white to-white lg:hidden"
              v-if="!controlledSwiperTab?.isEnd"
            >
              <button class="py-4 pl-10 text-blue" @click="controlledSwiperTab?.slideNext()">
                <ChevronRight />
              </button>
            </div>
          </template>
        </Swiper>
      </ClientOnly>
    </div>

    <slot name="content"></slot>
    <div class="px-4 lg:mt-6 lg:px-0">
      <ClientOnly fallback-tag="span" fallback="Loading ...">
        <Swiper
          :slides-per-view="1"
          :space-between="0"
          class="tabs-content"
          :allow-touch-move="false"
          :modules="[Controller]"
          :controller="{ control: controlledSwiper }"
          @swiper="setSwiper"
        >
          <Swiper-slide v-for="(item, index) in tabs" :key="index">
            <div>
              <h3 class="mb-4 font-sans text-base font-bold lg:text-xl">
                {{ item.title }}
              </h3>
              <div class="font-sans text-sm leading-normal lg:text-base" v-html="item.copy"></div>

              <LinkSecondary
                v-if="item?.link?.url"
                :link="item?.link?.url"
                :title="item?.link?.title"
                class="mt-8"
                type="blue"
                :external="item.link?.type === 'ext'"
              />
            </div>
          </Swiper-slide>
        </Swiper>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import { ref, type Component, computed } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Swiper as SwiperType } from 'swiper'
import { type SwiperOptions } from 'swiper/types'
import { Controller, Mousewheel } from 'swiper/modules'

import LinkSecondary from '../atoms/LinkSecondary.vue'
import ChevronRight from '../atoms/Icons/ChevronRight.vue'

import type { ILinkData } from '../../types'

export interface ITab {
  title?: string
  copy?: string
  link?: ILinkData
  tabLabel?: string
  icon?: Component
  iconClass?: string
}

interface Props {
  tabs?: ITab[]
}

const props = defineProps<Props>()

const activeTab = ref(0)
const controlledSwiper = ref<SwiperType | undefined>()
const controlledSwiperTab = ref<SwiperType | undefined>()

const setActiveTab = (index: number) => {
  activeTab.value = index
  controlledSwiper.value?.slideTo(index)
  controlledSwiperTab.value?.slideTo(index)
}

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
}

const setSwiperTab = (swiper: SwiperType) => {
  controlledSwiperTab.value = swiper
}

const hasIcon = computed(() => props.tabs?.some(tab => tab.icon))
const breakpoints = computed<SwiperOptions['breakpoints']>(() => {
  return {
    320: {
      slidesPerView: 'auto',
      spaceBetween: hasIcon.value ? 0 : 18,
    },
    1024: {
      slidesPerView: 'auto',
      spaceBetween: 50,
    },
  }
})
</script>

<style>
.tabs .swiper-slide {
  width: auto;
}

@media (max-width: 1023px) {
  .has-icon .swiper-wrapper {
    justify-content: space-between;
  }
}

.tabs,
.tabs-content {
  padding-bottom: 1em !important;
}

.tabs .swiper-slide {
  z-index: 100;
}
</style>
