<template>
  <Container class="px-0 md:px-4">
    <div
      v-if="tall"
      class="relative aspect-[440/650] w-full self-start overflow-hidden rounded-2xl md:aspect-video"
    >
      <component
        :is="component"
        :url="data?.video?.url || data?.video?.file"
        class="absolute h-full w-full"
      />
    </div>
    <div v-else>
      <component
        :is="component"
        :url="
          data?.video?.url ||
          data?.video?.file ||
          data?.verticalVideo?.url ||
          data?.verticalVideo?.file
        "
        :params="data?.video?.params || data?.verticalVideo?.params"
        :class="
          twMerge('aspect-video w-full self-start sm:rounded-2xl', isVertical && 'hidden lg:block')
        "
      />
      <component
        :is="component"
        :url="data?.verticalVideo?.url || data?.verticalVideo?.file"
        :params="data?.video?.params || data?.verticalVideo?.params"
        :class="
          twMerge(
            'mx-auto hidden aspect-[9/16] max-h-svh w-full sm:h-[645px] sm:w-auto sm:rounded-2xl',
            isVertical && 'block lg:hidden',
          )
        "
      />
    </div>
  </Container>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import VideoIframe from '../atoms/VideoIframe.vue'
import VideoPlayer from '../atoms/VideoPlayer.vue'
import Container from '../atoms/Container.vue'
import { twMerge } from 'tailwind-merge'

export interface IVideoBlock {
  id: string
  video?: {
    url: string
    file: string
    params?: string
  }
  verticalVideo?: {
    url: string
    file: string
    params?: string
  }
}

interface Props {
  data: IVideoBlock
  tall?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  tall: () => false,
})

const component = computed(() =>
  props?.data?.video?.url || props.data.verticalVideo?.url ? VideoIframe : VideoPlayer,
)

const isVertical = computed(() => !!props.data.verticalVideo)
</script>
