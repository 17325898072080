<template>
  <div>
    <h3 v-if="isProtest" class="mb-6 font-serif text-3xl font-bold text-black">
      <CountUpDisplay
        :key="petitionsKey"
        :end-val="petitions"
        :options="{
          enableScrollSpy: true,
          separator: ' ',
        }"
      />
      {{
        $t(
          'petitionCounter.protest',
          Number(petitions) === 4 ? Number(petitions) - 1 : Number(petitions),
        )
      }}
      -
      {{
        isClosed
          ? $t('petitionCounter.closed')
          : $t('petitionCounter.title') + ' ' + goal.toLocaleString('sv')
      }}!
    </h3>
    <h3 v-else class="mb-6 font-serif text-3xl font-bold text-black">
      <CountUpDisplay
        :key="petitionsKey"
        :end-val="petitions"
        :options="{
          enableScrollSpy: true,
          separator: ' ',
        }"
      />
      {{
        $t(
          'petitionCounter.petition',
          Number(petitions) === 4 ? Number(petitions) - 1 : Number(petitions),
        )
      }}
      -
      {{
        isClosed
          ? $t('petitionCounter.closed')
          : $t('petitionCounter.title') + ' ' + goal.toLocaleString('sv')
      }}!
    </h3>
    <div class="mb-6 h-10 w-full rounded-lg bg-grey-light lg:h-12 lg:w-[340px]">
      <div
        class="transition-width h-10 rounded-lg bg-blue duration-1000 lg:h-12"
        :style="percent"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { standardSeries, findNext } from '~/libs/petitions'
import { ref as dbRef, onValue } from 'firebase/database'
import { CountUpDisplay } from 'refresh-ui'

const props = defineProps({
  isClosed: {
    type: Boolean,
    required: false,
    default: false,
  },
  formId: {
    type: String,
    required: true,
  },
  isProtest: {
    type: Boolean,
    required: false,
    default: false,
  },
})

// Bind to realtime counter value
const db = useDatabase()
const countRef = dbRef(db, 'counters/' + props.formId)
const petitionsKey = ref(0)
const petitions = ref(0)

onValue(countRef, async snapshot => {
  petitions.value = snapshot.val() || 0
  petitionsKey.value++
})

// Calculate counter per
const percent = computed(() => 'width:' + (petitions.value / goal.value) * 100 + '%')

// Calculate goal
const goal = computed(() => {
  return props.isClosed ? petitions.value : findNext(petitions.value, standardSeries)
})
</script>
