<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="data?.cards?.length > 1" class="relative">
    <Swiper
      :modules="[Controller, Navigation]"
      :controller="{ control: controlledSwiper }"
      :slides-per-view="1"
      :space-between="0"
      :loop="true"
      :allow-touch-move="false"
      @swiper="setSwiper"
    >
      <SwiperSlide v-for="(card, index) in [...data?.cards, ...data?.cards]" :key="index">
        <div
          class="relative flex aspect-[3/2] w-full items-center lg:aspect-auto lg:h-[700px] lg:px-14"
        >
          <NuxtImg
            v-if="card?.image?.url"
            :provider="props.provider"
            :src="card?.image?.url"
            :alt="card?.image?.alt"
            class="absolute top-0 aspect-[375/230] w-full object-cover object-center lg:inset-0 lg:aspect-auto lg:h-full"
            loading="lazy"
            sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
            fit="fill"
            width="1920"
            height="700"
          />
          <Cta
            :sub-title="card?.subtitle"
            :title="card?.title"
            :content="card?.copyDesktop"
            :content-secondary="card?.copyMobile"
            :link="card?.link"
            :has-form-component="card.hasFormComponent"
            :form-component-id="card.formComponentId"
            class="mt-[45%] lg:mt-0"
          >
            <template #default="{ formComponentId }">
              <slot
                name="default"
                :form-component-id="formComponentId"
                :petition-id="item?.petitionId"
              ></slot>
            </template>
          </Cta>
        </div>
      </SwiperSlide>
    </Swiper>

    <div class="absolute right-8 top-20 z-20 flex flex-col justify-center lg:inset-y-0 lg:right-32">
      <button
        class="flex h-12 w-12 items-center justify-center rounded-full bg-blue hover:bg-blue-darker lg:h-14 lg:w-14"
        aria-label="next"
        @click="nextSlide"
      >
        <ArrowRightSmall />
      </button>
    </div>
  </div>
  <div v-else-if="data?.cards?.length === 1">
    <div
      class="relative flex aspect-[3/2] w-full items-center lg:aspect-auto lg:h-[700px] lg:px-14"
    >
      <NuxtImg
        v-if="item?.image?.url"
        :src="item?.image?.url"
        :alt="item?.image?.alt"
        :provider="props.provider"
        class="absolute top-0 aspect-[375/308] w-full object-cover object-center lg:inset-0 lg:aspect-auto"
        :class="[
          getImageClass(item?.field_image_variant),
          {
            'lg:h-full': item?.field_image_variant !== 'short_on_desktop',
          },
        ]"
        loading="lazy"
        sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        fit="fill"
        width="1920"
        height="700"
      />

      <Cta
        :sub-title="item?.subtitle"
        :title="item?.title"
        :content="item?.copyDesktop"
        :content-secondary="item?.copyMobile"
        :link="item?.link"
        :has-form-component="item?.hasFormComponent"
        :form-component-id="item?.formComponentId"
        :class="getFormClass(item?.field_image_variant)"
      >
        <template #default="{ formComponentId }">
          <slot
            name="default"
            :form-component-id="formComponentId"
            :petition-id="item?.petitionId"
          ></slot>
        </template>
      </Cta>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ref, computed, watch } from 'vue'
import type { Ref } from 'vue'
import { Swiper as SwiperType } from 'swiper'
import { Controller, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useWindowSize } from '@vueuse/core'

import Cta from '../molecules/Cta.vue'
import ArrowRightSmall from '../atoms/Icons/ArrowRightSmall.vue'
import NuxtImg from '../atoms/NuxtImg.vue'

import type { ICardData } from '../../types'

export interface ICardBigHeroCtaData extends ICardData {
  copyDesktop: string
  copyMobile: string
  hasFormComponent: boolean
  formComponentId: string
  petitionId?: string
  field_image_variant?: 'standard' | 'short_on_mobile' | 'short_on_desktop' | 'short_on_all'
}

export interface IBigHeroCta {
  id: string
  cards: ICardBigHeroCtaData[]
}

interface Props {
  data: IBigHeroCta
  provider?: 'cloudinary' | 'abs'
}

const props = withDefaults(defineProps<Props>(), {
  provider: 'cloudinary',
})

const item = computed(() => props.data?.cards?.[0])

const { width } = useWindowSize()

const controlledSwiper: Ref<SwiperType | undefined> = ref()

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
}

const nextSlide = () => controlledSwiper.value?.slideNext(500, false)

const widthScreen = computed(() => width.value)

watch(widthScreen, () => {
  if (!controlledSwiper?.value) {
    return
  }
  controlledSwiper?.value.update()
})

const getImageClass = (variant?: string) => {
  switch (variant) {
    case 'standard':
      return ''
    case 'short_on_mobile':
      return 'h-[230px] sm:h-auto'
    case 'short_on_desktop':
      return 'lg:h-[550px]'
    case 'short_on_all':
      return 'h-[230px] lg:h-[550px]' // Short image for both mobile & desktop
    default:
      return ''
  }
}

const getFormClass = (variant?: string) => {
  switch (variant) {
    case 'standard':
      return `mt-[65%] lg:mt-0` // Default margin
    case 'short_on_mobile':
      return `mt-[11rem] sm:mt-[65%] lg:mt-0` // Less margin for mobile, no change on desktop
    case 'short_on_desktop':
      return `mt-[65%] lg:mt-0` // Slight overlap on desktop
    case 'short_on_all':
      return 'mt-[11rem] lg:mt-0' // Overlap for both mobile & desktop
    default:
      return `mt-[65%] lg:mt-0`
  }
}
</script>

<style scoped>
.swiper {
  padding-bottom: 0px;
}
</style>
